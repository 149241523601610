p {
  max-width: 80ch;
  word-wrap: break-word;
}

pre {
  max-width: 100ch;
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*============================================================================
# Layout
==============================================================================*/

.sidebar-menu .MuiMenuItem-root {
  height: 48px !important;
}

/*============================================================================
# Misc
==============================================================================*/

.avatar img {
  width: 5rem;
  height: 5rem;
  object-fit: contain;
}

.previews img {
  margin: 0;
  height: 5rem;
  max-width: 100%;
  object-fit: contain;
}

.artwork img {
  width: 15rem;
  max-height: 20rem;
  object-fit: contain;
}

.uploaded-url {
  width: 100% !important;
}

/*============================================================================
# JSON pretty
==============================================================================*/

.custom-json-pretty {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;

  max-width: 60rem;
}

/*============================================================================
# Shipping Notif Dialog
==============================================================================*/

p.warning {
  width: 100%;
  color: red;
}